ion-popover.quota-balance-quota-description {
  --background: var(--ion-color-step-100);
  --width: 80%;
}

ion-popover.species-popover {
  --width: 150px;

  &::part(content) {
    border-radius: 8px;
    --offset-x: -10px;
  }

  &::part(arrow) {
    display: none;
  }
}
