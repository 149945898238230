/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import 'styles/slides';
@import 'styles/rich-text';
@import 'styles/alert';
@import 'styles/modal';
@import 'styles/popover';
@import 'styles/rec-header';
@import 'styles/icons';
@import 'styles/skeleton';
@import 'styles/div-icon';
@import 'styles/leaflet-popup';
@import 'styles/slides';
@import 'styles/button';
@import 'styles/pulse';
@import '../../../node_modules/leaflet/dist/leaflet.css';
@import '../../../node_modules/leaflet.markercluster/dist/MarkerCluster.css';
@import '../../../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css';

ion-app.ion-page {
  bottom: var(--ion-safe-area-bottom);
}

ion-select.has-value::part(icon) {
  display: none;
}

ion-button {
  min-height: auto;
}

html {
  --ion-backdrop-opacity: 0.5;

  --stats-total-color: #7dce82;

  --stats-kept-color: #04a777;
  --stats-released-color: #00fff5;
}

ion-label.required::after,
ion-label[required='true']::after {
  content: '*';
  color: var(--ion-color-danger);
}

span.required {
  color: var(--ion-color-danger);
}

.date-time-popover {
  --width: auto;
}

ion-label.title {
  font-weight: bold;
}

.readonly {
  pointer-events: none;
}

.div-icon {
  background-color: transparent;
  border: none;

  img {
    height: 100%;
    width: 100%;
  }
}
.prefered-marker {
  &::after {
    content: '';
    position: absolute;
    --size: 20px;
    --inset: -4px;
    top: var(--inset);
    right: var(--inset);
    height: var(--size);
    aspect-ratio: 1;

    background: url('./assets/images/star.svg');
  }
}
