@import '../mixins/media';

@mixin full-screen-transparent-wrapper {
  &::part(content) {
    width: 100%;
    height: 100%;
    background: transparent;
  }
}

@mixin full-screen-transparent-blur-wrapper {
  &::part(content) {
    width: 100%;
    height: 100%;
    background: transparent;
    backdrop-filter: blur(3px);
  }
}

.species-detail-instruction-modal {
  &::part(backdrop) {
    --backdrop-opacity: 0.5;
  }

  @include full-screen-transparent-blur-wrapper;
}

.instruction-modal {
  ion-header {
    ion-toolbar {
      --background: transparent;
      --border-width: 0;
    }
  }

  ion-content {
    --background: transparent;
  }

  &::part(backdrop) {
    --backdrop-opacity: 0.5;
  }

  ion-button.dismiss {
    height: 40px;
    width: 40px;
    --border-radius: 50%;
  }

  .container {
    transition: opacity 0.2s ease-in-out;

    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }
  ion-icon.arrow {
    font-size: 56px;
    padding: 0 0 0 3px;
  }

  p > span {
    color: var(--ion-color-primary);

    &.danger {
      color: var(--ion-color-danger);
    }
  }

  .stepper {
    position: absolute;

    left: 0;
    right: 0;
    bottom: 3rem;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    .step {
      height: 0.5rem;
      aspect-ratio: 1;
      border-radius: 50%;

      background-color: gray;
      transition: background-color 0.2s ease-in-out;

      &.active {
        background-color: var(--ion-color-primary);
      }
    }
  }

  @include full-screen-transparent-blur-wrapper;
}

.pending-transaction-modal {
  &::part(backdrop) {
    --backdrop-opacity: 0.8;
  }

  @include full-screen-transparent-blur-wrapper;
}

.about-my-fish-location-modal {
  &::part(backdrop) {
    --backdrop-opacity: 0.8;
  }

  &::part(content) {
    position: absolute;
    left: 10px;
    right: 10px;
    height: 70%;
    width: auto;
    bottom: 30px;
    background: transparent;
    border-radius: 20px;
  }
}

.species-list-instruction-modal {
  &::part(backdrop) {
    --backdrop-opacity: 0.6;
  }

  @include full-screen-transparent-wrapper;
}

.can-i-fish-here-instruction-modal {
  &::part(backdrop) {
    --backdrop-opacity: 0.8;
  }

  @include full-screen-transparent-wrapper;
}

.which-fish-is-this-instruction-modal {
  &::part(backdrop) {
    --backdrop-opacity: 0.7;
  }

  @include full-screen-transparent-blur-wrapper;
}

@mixin attribute-modal-modal-wrapper {
  top: unset;
  &::part(backdrop) {
    --backdrop-opacity: 0;
  }
  &::part(content),
  .modal-shadow {
    border-radius: 20px;
    top: 30px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    width: auto;
    height: auto;

    @include full-screen-iphone {
      bottom: 15px;
      border-radius: 40px;
    }

    position: absolute;
    background: transparent;
  }

  &::part(handle) {
    margin-top: 0.25rem;
    width: 40%;

    &::before {
      height: 50px;
      width: 100%;
    }
  }
}

.current-location-modal {
  height: 200px;
  bottom: 72px;

  @include attribute-modal-modal-wrapper;
}

.layer-modal-high {
  height: 650px;
  top: unset;

  @include attribute-modal-modal-wrapper;
}

.layer-modal-low {
  height: 360px;
  top: unset;

  @include attribute-modal-modal-wrapper;
}

ion-modal.attribute-modal-high {
  height: 500px;

  @include attribute-modal-modal-wrapper;
}

ion-modal.attribute-modal-low {
  height: 300px;
  top: unset;

  @include attribute-modal-modal-wrapper;
}

.disclaimer-modal {
  &::part(backdrop) {
    --backdrop-opacity: 0.8;
  }

  &::part(content),
  .modal-shadow {
    width: calc(100% - 30px);
    height: 246px;
    @media screen and (min-width: 375px) {
      height: 225px;
    }
    @media screen and (min-width: 414px) {
      height: 225px;
    }
    @media screen and (min-width: 768px) {
      height: 183px;
    }
    background: var(--ion-background-color);
    border-radius: 20px;

    position: absolute;
    bottom: 15px;
  }
}

@mixin modal-wrapper {
  &::part(backdrop) {
    --backdrop-opacity: 0.8;
  }

  --height: auto;
  &::part(content),
  .modal-shadow {
    position: absolute;
    left: 0;
    right: 0;
    height: 498px;
    width: auto;
    bottom: 0;
    background: transparent;
    border-radius: 20px 20px 0 0;
  }
}

@mixin grid-site-modal-wrapper {
  top: unset;
  &::part(backdrop) {
    --backdrop-opacity: 0;
  }
  &::part(content),
  .modal-shadow {
    border-radius: 20px;
    top: 30px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    width: auto;
    height: auto;

    @include full-screen-iphone {
      bottom: 15px;
      border-radius: 40px;
    }
    position: absolute;
    background: transparent;
  }
}

ion-modal.commercial-manual-polling-modal,
ion-modal.commercial-location-modal,
ion-modal.commercial-add-species-modal,
ion-modal.commercial-add-quota-modal,
ion-modal.commercial-add-item-modal {
  @include modal-wrapper;
}

ion-modal.rec-unfinished-report-reminder-modal {
  --height: auto;

  &::part(content) {
    height: 180px;
  }
}

ion-modal.rec-login-reminder-modal {
  --height: auto;

  &::part(content) {
    height: 250px;
  }
}

ion-modal.grid-site-info-modal {
  height: 150px;
  top: unset;
  @include grid-site-modal-wrapper;
}

ion-modal.grid-site-layer-modal {
  height: 250px;
  top: unset;
  @include grid-site-modal-wrapper;
}

ion-modal.grid-site-map-modal {
  height: 90%;
  top: unset;

  border-radius: 20px;
  @include full-screen-iphone {
    bottom: 15px;
    border-radius: 40px;
  }

  &::part(backdrop) {
    --backdrop-opacity: 0.8;
  }

  .modal-wrapper,
  .modal-shadow {
    position: absolute;
    left: 0;
    right: 0;
    height: 90%;
    width: auto;
    bottom: 0;
    background: transparent;
    border-radius: 20px 20px 0 0;
  }
}
