swiper-container {
  span {
    line-height: 18px;
    vertical-align: middle;

    &:not(.swiper-pagination-bullet-active) {
      background: white;
      opacity: 0.5;
      width: 5px;
      height: 5px;
      transition: width 0.3s, height 0.3s;
    }

    &.swiper-pagination-bullet-active {
      width: 10px;
      height: 10px;
      transition: width 0.5s, height 0.5s;
      background: red;
    }
  }

  &.species-photo,
  &.take-photo-slides,
  &.age-info-photo,
  &.which-fish-is-this-photo,
  &.fish-identify-result-slides {
    div.swiper-pagination {
      position: absolute;
      bottom: 0;
      height: 18px;
    }
  }
}
