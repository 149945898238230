.feature-popup {
  width: calc(100vw - 10px);
  color: var(--ion-color-dark);

  div.leaflet-popup-content-wrapper {
    background: rgba(var(--ion-background-color-rgb), 0.9);
  }

  div.leaflet-popup-tip-container {
    div.leaflet-popup-tip {
      background: rgba(var(--ion-background-color-rgb), 0.9);
    }
  }

  ion-row {
    ion-col {
      font-size: 14px;
      color: var(--ion-color-step-800);

      &:first-child {
        color: var(--ion-color-step-900);
        font-weight: 600;
      }
    }
  }
}
