@mixin full-screen-iphone {
  @media screen and (width: 375px) and (height: 812px),
    (width: 414px) and (height: 896px),
    (width: 428px) and (height: 926px),
    (width: 360px) and (height: 780px),
    (width: 390px) and (height: 844px),
    (width: 390px) and (height: 844px),
    (width: 428px) and (height: 926px),
    (width: 393px) and (height: 852px),
    (width: 430px) and (height: 932px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-device-width: 768px) {
    @content;
  }
}
