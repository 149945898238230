ion-button.main-button {
  //
}

ion-button.secondary-button {
  --background: transparent;

  --button-color: var(--ion-color-primary, #3880ff);
  --color: var(--button-color);
  --border-color: --button-color;
  --border-style: solid;
  --border-width: 2px;
}

ion-button.icon-button {
  --background: transparent;
  --background-activated: var(--background);
  --background-hover: var(--background);
  --background-focused: var(--background);

  --button-color: var(--ion-color-primary, #3880ff);
  --color: var(--button-color);
  --border-color: var(--ion-color-step-200);
  --border-style: solid;
  --border-width: 0.5px;

  ion-row {
    width: 100%;

    span {
      color: white;
      opacity: var(--placeholder-opacity, 0.6);
    }
  }

  &::part(native) {
    padding: 4px;
  }

  ion-icon {
    border: 1px solid var(--button-color);
    border-radius: 50%;
    padding: 0.25rem;
    margin-right: 20px;

    height: 25px;
    width: 25px;
  }
}

ion-button.main-button,
ion-button.secondary-button,
ion-button.icon-button {
  --border-radius: 50px;
  margin-bottom: 2rem;

  &.dense {
    margin: 0;
  }
}
