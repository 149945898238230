.qld-alert {
  background: rgba(0, 0, 0, 0.7);

  .alert-wrapper {
    border-radius: 20px;
    background: var(--ion-color-step-50);

    span {
      color: var(--ion-color-primary);

      &.danger {
        color: red;
      }
    }

    .danger-alert-button {
      span {
        color: red;
      }
    }
  }
}

.alert-checkbox-label.sc-ion-alert-ios,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}
