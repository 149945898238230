@import '../mixins/zoom-in-out';

$start-width: 30px;
$start-font-size: 12px;

.cluster {
  div {
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: solid 3px red;
    background: var(--ion-background-color);

    p {
      font-weight: 500;
      margin: 0;
      font-size: $start-font-size;
      color: var(--ion-color-dark);
    }
  }

  &-10 {
    div {
      height: $start-width;
      width: $start-width;
    }
  }

  &-50 {
    div {
      height: calc(#{$start-width} + 10px);
      width: calc(#{$start-width} + 10px);

      p {
        font-size: calc(#{$start-font-size} + 1px);
      }
    }
  }

  &-150 {
    div {
      height: calc(#{$start-width} + 20px);
      width: calc(#{$start-width} + 20px);

      p {
        font-size: calc(#{$start-font-size} + 2px);
      }
    }
  }

  &-300 {
    div {
      height: calc(#{$start-width} + 30px);
      width: calc(#{$start-width} + 30px);

      p {
        font-size: calc(#{$start-font-size} + 3px);
      }
    }
  }
}

.current-location-marker {
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: var(--ion-color-primary-contrast);

    div.inner {
      @include zoom-in-out;
      height: 15px;
      width: 15px;
      border-radius: 50%;
      background: var(--ion-color-primary);
    }
  }
}
