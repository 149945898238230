.pulse {
  animation: pulse 0.8s 3 ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: var(--ion-color-step-50);
  }
  50% {
    background-color: var(--ion-color-step-100);
  }
  100% {
    background-color: var(--ion-color-step-50);
  }
}
